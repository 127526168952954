import React, {PureComponent} from 'react';
import {ToggleBox} from "../components/ToggleBox";

export default class Impressum extends PureComponent<{}, {}> {
    render(): React.ReactNode {
        return (
            <div className='screen-container space-to-top'>
            <div className='impressum-toggle'>
                <ToggleBox title='Anschrift'>
                <div className='column-left'>
                    <div className='row-left'>
                        <div className='column'>
                        <p>Hoffmann GmbH<br /> Von-Steinbeis-Str. 16a <br />78476 Allensbach<br /> T +49 (0) 75 33 | 9 49 69 0 <br />F +49 (0) 75 33 | 9 49 69 69<br /></p>
                        <table>
                            <td>
                                <tr>E-Mail:</tr>
                                <tr>Homepage:</tr>
                                <tr>Sitz der Gesellschaft:</tr>
                                <tr>Registergericht:</tr>
                                <tr>Registernummer:</tr>
                                <tr>USt-IdNr:</tr>
                                <tr>Geschäftsführer:</tr>
                            </td>
                            <td>
                                <tr>kontakt(at)va-h.de</tr>
                                <tr>www.va-h.de</tr>
                                <tr>Allensbach</tr>
                                <tr>Amtsgericht Freiburg</tr>
                                <tr>HRB 382084</tr>
                                <tr>DE264095132</tr>
                                <tr>Urs Hoffmann</tr>
                            </td>
                        </table>
                        </div>
                    </div>
                </div>
                </ToggleBox>
                <ToggleBox title='Allgemein - Versicherungsberatung'>
                    <div>
                        <p>Die Hoffmann GmbH ist gemäß § 34d Abs.1 GewO als Versicherungsmakler, Bundesrepublik
                            Deutschland, tätig. Die Erlaubnis wurde durch die Industrie- und Handelskammer Hochrhein Bodensee, Reichenaustraße 21, 78467 Konstanz erteilt.
                        <br/>
                        <br/>
                        Diese Tätigkeit beinhaltet auch Beratung.<br/> Die Vergütung der Tätigkeit erfolgt als:
                        <ul>
                        <li>Konkret vereinbarte Zahlung durch den Kunden oder als</li>
                        <li>In der Versicherungsprämie enthaltene Provision, die vom jeweiligen Versicherungsunternehmen ausgezahlt wird oder als</li>
                        <li>Kombination aus beidem.</li>
                        </ul> 
                        Dies ist letztlich abhängig von den Wünschen und Bedürfnissen des Kunden und den Versicherungsprodukten, welche eventuell vermittelt werden.</p>
                        <p>Die Hoffmann GmbH hält keine direkten oder indirekten Beteiligungen von mehr als 10% der Stimmrechte oder des Kapitals an einem Versicherungsunternehmen.
                            Ein Versicherungsunternehmen oder das Mutterunternehmen eines Versicherungsunternehmens hält keine direkte oder indirekte Beteiligung von mehr als 10% der
                            Stimmrechte oder des Kapitals an der Hoffmann GmbH.</p>
                        <p>Berufsrechtliche Regelungen: § 34d Gewerbeordnung, §§ 59-68 VVG, VersvermV.</p>
                        <p>Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage <a href="https://www.gesetze-im-internet.de" rel="noreferrer" target="_blank">www.gesetze-im-internet.de</a> eingesehen und abgerufen werden.</p>
                        <p>Die Hoffmann GmbH verfügt über Deckung durch eine Vermögensschadenhaftpflichtversicherung für die genannten Tätigkeiten in gesetzlich vorgeschriebenem Umfang. Diese wurde gegenüber der IHK nachgewiesen. Ohne diesen Nachweis ist eine Registrierung nicht möglich.</p>                   
                    </div>
                </ToggleBox>
                <ToggleBox title='Aufsichtsbehörde Versicherungsberatung'>
                    <>
                        <p>Zuständige Aufsichtsbehörde für die Versicherungsberatung bzw. -vermittlung: Industrie- und Handelskammer Hochrhein Bodensee, Reichenaustraße 21, 78478 Konstanz. < br/> <a href="https://www.konstanz.ihk.de" rel="noreferrer" target="_blank">www.konstanz.ihk.de</a></p>
                        <p>Registernummer: D-LXR5-SSHX1-50</p>
                        <p>Zu überprüfen über das Vermittlerregister für Versicherungsberater bzw. -vermittler, das geführt wird bei: Deutscher Industrie- und Handelskammertag (DIHK) e.V., Breite Str. 29, 10178 Berlin. < br/><a href="https://www.vermittlerregister.info" rel="noreferrer" target="_blank">www.vermittlerregister.info</a></p>
                    </>
                </ToggleBox>
                <ToggleBox title='Allgemein - Finanzanlagenberatung'>
                        <p>Die Hoffmann GmbH ist ebenfalls gemäß § 34f Abs.1 Satz 1 Nr.1, Nr.2 und Nr.3 der GewO als Finanzanlagenberater bzw. -vermittler tätig. Die Gewerbeerlaubnis wurde durch die Industrie- und Handelskammer Hochrhein Bodensee, Reichenaustraße 21, 78467 Konstanz, erteilt.</p>
                        <p>Diese Erlaubnis beinhaltet die Befugnis, Anlageberatung zu erbringen, oder den Abschluss von Verträgen über den Erwerb der folgenden Finanzanlagen zu vermitteln:
                        <ul>
                            <li>Anteilscheine einer Kapitalanlagengesellschaft oder Investmentaktiengesellschaften oder von ausländischen Investmentanteilen, die im Geltungsbereich des Investmentgesetztes öffentlich vertrieben werden dürfen (§ 34f Abs. 1 Satz 1 Nr.1 GewO)</li>
                            <li>Anteile an geschlossenen Fonds in Form einer Kommanditgesellschaft (§ 34f Abs. 1 Satz 1 Nr.2 GewO)</li>
                            <li>Sonstige Vermögensanlagen im Sinne des § 1 Abs. 2 des Vermögensanlagengesetzes (§ 34f Abs. 1 Satz 1 Nr.3 GewO)</li>
                        </ul></p>
                        <br />
                        <p>Unsere Vertragspartner im Bereich der Finanzanlagenberatung bzw. -vermittlung</p>
                        <p>Jung, DMS & Cie. AG<br />DE – 80538 München, <a href="https://www.jungdms.de" rel="noreferrer" target="_blank">www.jungdms.de</a></p>
                        <p>Allianz Global Investors Europe GmbH Marketing<br /> DE - 60323 Frankfurt am Main, <a href="https://www.allianzglobalinvestors.de" rel="noreferrer" target="_blank">www.allianzglobalinvestors.de</a></p>
                        <p>Ampega Investment GmbH<br />DE - 50679 Köln, <a href="https://www.ampegagerling.de" rel="noreferrer" target="_blank">www.ampegagerling.de</a></p>
                        <p>DEGAG Deutsche Grundbesitz AG<br />DE - 30159 Hannover, <a href="https://www.degag-wohnen.de" rel="noreferrer" target="_blank">www.degag-wohnen.de</a></p>
                        <p>Deutsche Asset & Wealth Management International GmbH<br />DE - 60327 Frankfurt am Main, <a href="https://www.deawm.com<" rel="noreferrer" target="_blank">www.deawm.com</a></p>
                        <p>Deutsche Finance Group<br />DE - 80804 München, <a href="https://www.deutsche-finance-group.de<" rel="noreferrer" target="_blank">www.deutsche-finance-group.de</a></p>
                        <p>ETHENEA Independent Investors S.A. (Société Anonyme)<br />LU - 5365 Munsbach, <a href="https://ethenea.com" rel="noreferrer" target="_blank">www.ethenea.com</a></p>
                        <p>Flossbach von Storch AG<br />DE - 50679 Köln, <a href="https://www.fvsag.com" rel="noreferrer" target="_blank">www.fvsag.com</a></p>
                        <p>M&G International Investments Ltd.<br />DE - 60325 Frankfurt am Main, <a href="http://www.mandg.de" rel="noreferrer" target="_blank" >www.mandg.de</a></p>
                        <p>Primus Valor AG<br />DE - 68163 Mannheim, <a href="http://www.primusvalor.com" rel="noreferrer" target="_blank">www.primusvalor.com</a></p>
                        <p>RWB PrivateCapital Emissionshaus AG<br />DE - 82041 Oberhaching, <a href="https://www.rwb-ag.de" rel="noreferrer" target="_blank" >www.rwb-ag.de</a></p>
                        <p>Solvium Capital GmbH<br />DE - 20459 Hamburg, <a href="http://www.solvium-capital.de" rel="noreferrer" target="_blank" >www.solvium-capital.de</a></p>
                        <p>THAMM & PARTNER GmbH<br /> DE - 10789 Berlin, <a href="www.ThammundPartner.de" rel="noreferrer" target="_blank" >www.ThammundPartner.de</a>, <a href="https://www.ThammImmobilien.de" rel="noreferrer" target="_blank">www.ThammImmobilien.de</a></p>
                        <p>UBS AG <br />CH - 8001 Zürich, <a href="http://www.ubs.com" rel="noreferrer" target="_blank" >www.ubs.com</a></p>
                        <p>DWS Investment GmbH <br />DE – 60329 Frankfurt am Main, <a href="https://www.dws.de " rel="noreferrer" target="_blank">www.dws.de</a></p>
                        <p>M-Immo AG <br />DE – 65197 Wiesbaden, <a href="https://www.m-immo-ag.de" rel="noreferrer" target="_blank">www.m-immo-ag.de</a></p>
                        <p>Terra Sozialbau GmbH<br />DE – 50226 Frechen</p>

                        <br />
                        <p>Aufklärung über Zuwendung nach § 12a FinVermV</p>

                        <p>Die Vermittlung von Finanzinstrumenten erfolgt ohne direkte Berechnung eines Entgelts an Sie. Zur Deckung des Vermittlungsaufwands erhält Ihr Berater / Vermittler / Vertragspartner und/oder weitere am Vertrieb 
                        beteiligte Unternehmen und/oder Personen vom Produktgeber bzw. von Geschäftspartnern Zuwendungen in Form von Geldzahlungen (Ausgabeaufschlag, Vertriebsfolgeprovision, ggf. Anteile aus der Depotgebühr, ggf. 
                        Marketingzuschuss/Innenprovision) oder sonstigen geldwerten Vorteilen. Gesetzgeberisches Ziel ist es, dass diese Zuwendungen Ihren Interessen als Kunden nicht entgegenstehen, sondern dafür eingesetzt werden, die 
                        Qualität der von Ihrem Berater / Vermittler / Vertragspartner erbrachten Finanzdienstleistung aufrechtzuerhalten und weiter zu verbessern.</p>

                        <p>Beim Erwerb von Anteilen an Investmentfonds erhält Ihr Berater / Vermittler / Vertragspartner je nach Verwahrstelle maximal 100% des im aktuellen Verkaufsprospekt des jeweiligen Investmentfonds angegebenen 
                        maximal gültigen Ausgabeaufschlags (siehe vorstehende Kostenaufklärung). Berechnungsbeispiel: Ausgabeaufschlag des Investmentfonds 5% bei einem investierten Betrag in Höhe von 10.000 Euro = 500 Euro Vergütung. 
                        Investmentfonds können grundsätzlich entweder in einem Investmentdepot bei den Fondsgesellschaften oder in einem Wertpapierdepot bei einer Bank geführt werden mit unterschiedlich hohen 
                        Anteilen am Ausgabeaufschlag der über 100 möglichen Verwahrstellen im In- und Ausland. Eine exakte Angabe der Vergütung ist deshalb nur möglich bezogen auf einen bestimmten Fonds bei bekannter Verwahrstelle. Auf 
                        Anfrage werden wir für Sie gerne die exakten Daten ermitteln. </p>

                        <p>Aus der Verwaltungsgebühr (siehe vorstehende Kostenaufklärung) erhält Ihr Berater / Vermittler / Vertragspartner für die laufende Kundenbetreuung bei einem Großteil der Investmentfonds ebenfalls einen 
                        Anteil, der bis zu 40% betragen kann. Diese Verwaltungsgebühren fallen für den Kunden immer an, unabhängig davon, ob der Investmentfonds von einer Bank oder einem Berater / Vermittler bezogen wurde und ob der 
                        Berater / Vermittler einen Anteil daraus erhält. Berechnungsbeispiel: Verwaltungsgebühr des Fonds 1% p.a. bei einem durchschnittlich investierten Betrag (zum jeweiligen Rücknahmepreis) in Höhe von 10.000 Euro = 100 
                        Euro Verwaltungsgebühr p.a.. Der Anteil Ihres Beraters / Vermittlers / Vertragspartners daraus beträgt je nach Fonds bis zu 40 Euro p.a.. Auf Anfrage ermitteln wir gerne die Höhe der prozentualen Verwaltungsgebühren 
                        des jeweiligen Fonds, sowie den Anteil, den Ihr Berater / Vermittler / Vertragspartner daraus erhält. Eine Vorausberechnung der Verwaltungsgebühren in Euro ist nicht möglich, da diese Gebühren von der zukünftigen 
                        Entwicklung der Wertpapier- und Wechselkurse beeinflusst werden. </p>

                        <p>Aus der Einstiegsgebühr bei Abschluss eines Vermögensverwaltungsvertrages (siehe vorstehende Kostenaufklärung) erhält Ihr Berater / Vermittler / Vertragspartner einen Anteil, der bis zu 100% betragen 
                        kann, dies bedeutet beispielsweise bei einem Anlagebetrag von 10.000 Euro und einer Einstiegsgebühr in Höhe von 5% eine Vergütung von 500 Euro. Aus dem Managemententgelt erhält Ihr Berater / Vermittler / 
                        Vertragspartner einen Anteil der bis zu 65% betragen kann. Die aktuelle Höhe der jeweiligen Einstiegsgebühr sowie des jeweiligen Managemententgelts richtet sich nach dem jeweils gültigen Preisverzeichnis.</p>

                        <p>Beim Ersterwerb von geschlossenen Fonds erhält Ihr Berater / Vermittler / Vertragspartner maximal 100% des im Emissionsprospekt angegebenen Ausgabeaufschlags (siehe vorstehende 
                        Kostenberechnung), d.h. bei einem Anlagebetrag von 10.000 Euro, bis zu 500 Euro. Bei geschlossenen Fonds erhält Ihr Berater / Vermittler / Vertragspartner darüber hinaus einen Anteil aus 
                        den Kosten für die Eigenkapitalvermittlung (siehe vorstehende Kostenaufklärung), der bis zu 100% betragen kann, d.h. bei einem Anlagebetrag von 10.000 Euro bis zu 1.000 Euro. Die konkrete Höhe 
                        der Zuwendung variiert sowohl in Bezug auf den einzelnen Fondsanbieter als auch auf den jeweils gewählten Fonds. Die Zuwendungen sind Bestandteil der Erläuterungen und der 
                        zusammenfassenden Kostendarstellung im entsprechenden Verkaufsprospekt. Ihr Berater / Vermittler / Vertragspartner kann darüber hinaus weitere Zuwendungen z.B. in Form von Staffelprovisionen, 
                        Produktprüfungs- und Marketingzuschüssen erhalten oder geldwerte Sachleistungen in Form von Marktstudien, Analysen, Wertgutachten, Schulungsmaßnahmen, sowie die Durchführung besonderer 
                        Veranstaltungen. Bei wenigen geschlossenen Fonds erhält Ihr Berater / Vermittler / Vertragspartner auch eine laufende Vergütung von einem Emissionshaus, die bis zu 1% p.a. betragen kann. Auf 
                        Anfrage erhalten Sie gerne weitere Informationen zu den Provisionen, Zuwendungen und Vorteilen, die Ihr Berater / Vermittler / Vertragspartner von Dritten erhält. </p>

                        <p>Die Hoffmann GmbH führt keinerlei Beratung über Produkte wie z.B. Zertifikate, Aktien, Derivate durch und übt keinerlei Tätigkeiten aus bzw. gibt Empfehlungen ab, die eine Erlaubnis nach § 32 KWG erfordern.</p>
                        <p>Berufsrechtliche Regelungen: § 34f Gewerbeordnung, FinVermV.</p>
                        <p>Die berufsrechtlichen Regelungen können über die vom Bundesministerium der Justiz und von der juris GmbH betriebenen Homepage <a href="https://www.gesetze-im-internet.de" rel="noreferrer" target="_blank">www.gesetze-im-internet.de</a> eingesehen und abgerufen werden.</p>
                        <p>Die Hoffmann GmbH verfügt über Deckung durch eine Vermögensschadenhaftpflichtversicherung für die genannten Tätigkeiten in gesetzlich vorgeschriebenem Umfang. Diese wurde gegenüber der IHK nachgewiesen. Ohne diesen Nachweis ist eine Registrierung nicht möglich.</p>
                </ToggleBox>
                <ToggleBox title='Aufsichtsbehörde Finanzanlagenberatung'>
                    <>
                        <p>Zuständige Aufsichtsbehörde für die Finanzanlagenberatung bzw. -vermittlung: Industrie- und Handelskammer Hochrhein Bodensee, Reichenaustraße 21, 78467 Konstanz.< br/> <a href="https://www.konstanz.ihk.de" rel="noreferrer" target="_blank">www.konstanz.ihk.de</a></p>
                        <p>Registernummer: D-F-143-URE9-09</p>
                        <p>Zu überprüfen über das Vermittlerregister für Finanzanlagenberater bzw. -vermittler, das geführt wird bei: Deutscher Industrie- und Handelskammertag (DIHK) e.V., Breite Str. 29, 10178 Berlin.<br /> <a href="https://www.vermittlerregister.info"  rel="noreferrer" target="_blank">www.vermittlerregister.info</a></p>
                    </>
                </ToggleBox>
                <ToggleBox title='Schlichtungsstellen'>
                    <>
                        <p>Folgende Schlichtungsstellen können zur außergerichtlichen Streitbeilegung angerufen werden</p>
                        <p><b>Versicherungsombudsmann e.V.</b> <br />Postfach 08 06 32 | 10006 Berlin<br /><a href="https://www.versicherungsombudsmann.de" rel="noreferrer" target="_blank">www.versicherungsombudsmann.de</a><br />info@versicherungsombudsmann.de</p>
                        <p><b>Ombudsmann für die private Kranken- und Pflegeversicherung</b><br />Postfach 06 02 22 | 10052 Berlin<br /><a href="https://www.pkv-ombudsmann.de" rel="noreferrer" target="_blank">www.pkv-ombudsmann.de</a></p>
                        <p><b>Schlichtungsstelle für gewerbliche Versicherungs-, Anlage- und Kreditvermittlung</b><br />Postfach 10 14 24 | 20009 Hamburg<br /><a href="https://www.schlichtung-finanzberatung.de" rel="noreferrer" target="_blank">www.schlichtung-finanzberatung.de</a><br /> kontakt@schlichtung-finanzberatung.de</p><br/>
                        <p>Weitere Adressen von Schlichtungsstellen bzw. Informationen zur außergerichtlichen Streitbeilegung erhalten Sie bei der </p>
                        <p><b>Bundesanstalt für Finanzdienstleistungsaufsicht (BAFin)</b><br />Graurheindorfer Straße 108 | 53117 Bonn<br /><a href="https://www.bafin.de" rel="noreferrer" target="_blank">www.bafin.de</a></p>
                    </>
                </ToggleBox>
                <ToggleBox title='Haftung für Inhalte'>
                    <>
                        <p>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</p>
                        <p>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</p>
                    </>
                </ToggleBox>
                <ToggleBox title='Haftung für Links'>
                    <>
                        <p>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</p>
                        <p>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</p>
                    </>
                </ToggleBox>
                <ToggleBox title='Urheberrechte'>
                <div>
                    <p>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>
                    <p>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</p>
                </div>
                <div className='column-left'>
                    <div className='row-left'>
                        <div className='column'>
                        <p>Hoffmann GmbH<br /> Von-Steinbeis-Str. 16a <br />78476 Allensbach<br /> T +49 (0) 75 33 | 9 49 69 0 <br />F +49 (0) 75 33 | 9 49 69 69<br /></p>
                        <table>
                            <td>
                                <tr>E-Mail:</tr>
                                <tr>Homepage:</tr>
                                <tr>Sitz der Gesellschaft:</tr>
                                <tr>Registergericht:</tr>
                                <tr>Registernummer:</tr>
                                <tr>USt-IdNr:</tr>
                                <tr>Geschäftsführer:</tr>
                            </td>
                            <td>
                                <tr>kontakt(at)va-h.de</tr>
                                <tr>www.va-h.de</tr>
                                <tr>Allensbach</tr>
                                <tr>Amtsgericht Freiburg</tr>
                                <tr>HRB 382084</tr>
                                <tr>DE264095132</tr>
                                <tr>Urs Hoffmann</tr>
                            </td>
                        </table>
                </div>
            </div>
                    </div>
                </ToggleBox>
                </div>
            </div>
        )
    }
}
