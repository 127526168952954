import React, {PureComponent} from 'react'

interface IShowRoomProps {
    profiles: IProfile[]
}

interface IShowRoomStats {
    active: number,
}

export interface IProfile {
    picture: string,
    name: string,
    job: string,
    title: string,
}

export class ShowRoom extends PureComponent<IShowRoomProps, IShowRoomStats> {
    state = {
        active: 0
    }


    render(): React.ReactNode {
        const {profiles} = this.props
        const {active} = this.state

        const activeProfile = profiles[active]

        return (
            <>
                <div className="ShowRoom-active">
                    <div className="ShowRoom-active-pic">
                        <img className="ShowRoom-active-pic" alt="" src={activeProfile.picture}/>
                    </div>
                    <div className="column-center-mobile">
                        <h3>{activeProfile.name}</h3>
                        <span>{activeProfile.job}</span>
                        <span><b>{activeProfile.title}</b></span>
                    </div>
                </div>
                <div className="row-center ShowRoom-mobile">
                    {profiles.length > 0 && profiles.map((value, index) =>
                        <div className="ShowRoom-center ShowRoom-preview" key={index} onClick={()=>{this.setState({active: index})}}>
                            <img className="ShowRoom-preview-pic" alt="" src={value.picture}/>
                           
                            <span className='header-on-big-screens'><b>{value.name}</b></span>
                            <div className='showroom-info'>
                                <span className='only-on-big-screens-job'>{value.job}</span>
                             <span className='only-on-big-screens'><b>{value.title}</b></span>
                            </div>

                        </div>
                    )}
                </div>
            </>
        )
    }
}

interface ISingelPictureProps {
    profile: IProfile
}

export class SinglePicture extends PureComponent<ISingelPictureProps, {}> {
    render(): React.ReactNode {
        const {job, name, picture, title} = this.props.profile;

        return (
            <div className="SinglePicture">
                <div className="SinglePicture-pic">
                    <img className="SinglePicture-pic" alt="" src={picture}/>
                </div>
                <div className="column-center1 ie-showroom-wrap">
                    <h3>{name}</h3>
                    <span>{job}</span>
                    <span><b>{title}</b></span>
                </div>
            </div>
        )
    }
}
