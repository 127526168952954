import React from 'react'
import {Link} from 'react-router-dom'

export interface INavBarItem {
    id?: string,
    title: string,
    className?: string,
    key?: string | number,
    onClick?: ()=>void
}

export interface INavBarItemButtom extends INavBarItem{
    onClick?: ()=>void
}

export interface INavBarItemLink extends INavBarItem{
    to: string,
    onClick?: ()=>void
}


export interface INavBarItemScroll extends INavBarItem {
    toElementId: string,
}

export interface INavBarItemNewTab extends INavBarItem {
    link: string,
}


export function NavBarItem({id, title, className, key, onClick}: INavBarItemButtom) {
    return (
        <div className={className} id={id} key={key}>
            <span onClick={onClick} >{title}</span>
        </div>
    )
}

export function NavBarItemLink({id, title, className, key, to, onClick}: INavBarItemLink) {
    return (
    <div className={className} id={id} key={key} onClick={onClick}>
        <Link to={to} >{title}</Link>
    </div>
    )
}

export function NavBarItemNewTab({id, title, className, key, link, onClick}: INavBarItemNewTab) {
    return (
        <div className={className} id={id} key={key}>
            <a href={link} rel="noreferrer" target="_blank" onClick={onClick}>{title}</a>
        </div>
    )
}

