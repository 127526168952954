import React, {PureComponent} from 'react';
import {ToggleBox} from "../components/ToggleBox";

export default class Datenschutzerklärung extends PureComponent<{}, {}> {
    render(): React.ReactNode {
        return (
            <div className='screen-container space-to-top'>
                <div className='impressum-toggle'>
                <ToggleBox title='Datenschutzerklärung'>
                    <p>Wir informieren Sie nachfolgend gemäß den gesetzlichen Vorgaben des Datenschutzrechts (insb.
                        gemäß BDSG n.F. und der europäischen Datenschutz-Grundverordnung ‚DS-GVO‘) über die Art, den
                        Umfang und Zweck der Verarbeitung personenbezogener Daten durch unser Unternehmen. Diese
                        Datenschutzerklärung gilt auch für unsere Websites und Sozial-Media-Profile. Bezüglich der
                        Definition von Begriffen wie etwa „personenbezogene Daten“ oder „Verarbeitung“ verweisen wir auf
                        Art. 4 DS-GVO.</p>
                    <p>Name und Kontaktdaten des / der Verantwortlichen</p>
                    <p>Unser/e Verantwortliche/r (nachfolgend „Verantwortlicher“) i.S.d. Art. 4 Zif. 7 DS-GVO ist:</p>
                    <div className='column-left'>
                        <div className='row-left'>
                            <div className='column'>
                                <p>Hoffmann GmbH<br/> Von-Steinbeis-Str. 16a <br/>78476 Allensbach<br/> T +49 (0) 75 33
                                    | 9 49 69 0 <br/>F +49 (0) 75 33 | 9 49 69 69<br/></p>
                                <table>
                                    <td>
                                        <tr>E-Mail:</tr>
                                        <tr>Homepage:</tr>
                                        <tr>Sitz der Gesellschaft:</tr>
                                        <tr>Registergericht:</tr>
                                        <tr>Registernummer:</tr>
                                        <tr>USt-IdNr:</tr>
                                        <tr>Geschäftsführer:</tr>
                                    </td>
                                    <td>
                                        <tr>kontakt(at)va-h.de</tr>
                                        <tr>www.va-h.de</tr>
                                        <tr>Allensbach</tr>
                                        <tr>Amtsgericht Freiburg</tr>
                                        <tr>HRB 382084</tr>
                                        <tr>DE264095132</tr>
                                        <tr>Urs Hoffmann</tr>
                                    </td>
                                </table>
                            </div>
                        </div>
                    </div>
                </ToggleBox>
                <ToggleBox title='Datenarten, Zwecke der Verarbeitung und Kategorien betroffener Personen'>
                    <>
                   
                        <p>Arten der Daten, die wir verarbeiten:
                            Telefonnummer, E-Mail, Fax etc. </p>
                        <p>Zwecke der Verarbeitung nach Art. 13 Abs. 1 c) DS-GVO:
                            Kontaktaufnahme der Kunden</p>
                        <p>Kategorien der betroffenen Personen nach Art. 13 Abs. 1 e) DS-GVO:
                            Kunden und Besucher der Website</p>
                        <p>Die betroffenen Personen werden zusammenfassend als „Nutzer“ bezeichnet.</p>
                    </>
                </ToggleBox>
                <ToggleBox title='Rechtsgrundlagen der Verarbeitung personenbezogener Daten'>
                    <>
                        <p>Nachfolgend Informieren wir Sie über die Rechtsgrundlagen der Verarbeitung personenbezogener
                            Daten:</p>
                        <ul>
                            <li>Wenn wir Ihre Einwilligung für die Verarbeitung personenbezogenen Daten eingeholt haben,
                                ist Art. 6 Abs. 1 S. 1 lit. a) DS-GVO Rechtsgrundlage.
                            </li>
                            <li>Ist die Verarbeitung zur Erfüllung eines Vertrags oder zur Durchführung vorvertraglicher
                                Maßnahmen erforderlich, die auf Ihre Anfrage hin erfolgen, so ist Art. 6 Abs. 1 S. 1
                                lit. b) DS-GVO Rechtsgrundlage.
                            </li>
                            <li>Ist die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich, der wir
                                unterliegen (z.B. gesetzliche Aufbewahrungspflichten), so ist Art. 6 Abs. 1 S. 1 lit. c)
                                DS-GVO Rechtsgrundlage.
                            </li>
                            <li>Ist die Verarbeitung erforderlich, um lebenswichtige Interessen der betroffenen Person
                                oder einer anderen natürlichen Person zu schützen, so ist Art. 6 Abs. 1 S. 1 lit. d)
                                DS-GVO Rechtsgrundlage.
                            </li>
                            <li>Ist die Verarbeitung zur Wahrung unserer oder der berechtigten Interessen eines Dritten
                                erforderlich und überwiegen diesbezüglich Ihre Interessen oder Grundrechte und
                                Grundfreiheiten nicht, so ist Art. 6 Abs. 1 S. 1 lit. f) DS-GVO Rechtsgrundlage.
                            </li>
                        </ul>
                    </>
                </ToggleBox>
                <ToggleBox title='Weitergabe personenbezogener Daten an Dritte und Auftragsverarbeiter'>
                    <>
                        <p>Ohne Ihre Einwilligung geben wir grundsätzlich keine Daten an Dritte weiter. Sollte dies doch
                            der Fall sein, dann erfolgt die Weitergabe auf der Grundlage der zuvor genannten
                            Rechtsgrundlagen z.B. bei der Weitergabe von Daten an Online-Paymentanbieter zur
                            Vertragserfüllung oder aufgrund gerichtlicher Anordnung oder wegen einer gesetzlichen
                            Verpflichtung zur Herausgabe der Daten zum Zwecke der Strafverfolgung, zur Gefahrenabwehr
                            oder zur Durchsetzung der Rechte am geistigen Eigentum.
                            Wir setzen zudem Auftragsverarbeiter (externe Dienstleister z.B. zum Webhosting unserer
                            Websites und Datenbanken) zur Verarbeitung Ihrer Daten ein. Wenn im Rahmen einer
                            Vereinbarung zur Auftragsverarbeitung an die Auftragsverarbeiter Daten weitergegeben werden,
                            erfolgt dies immer nach Art. 28 DS-GVO. Wir wählen dabei unsere Auftragsverarbeiter
                            sorgfältig aus, kontrollieren diese regelmäßig und haben uns ein Weisungsrecht hinsichtlich
                            der Daten einräumen lassen. Zudem müssen die Auftragsverarbeiter geeignete technische und
                            organisatorische Maßnahmen getroffen haben und die Datenschutzvorschriften gem. BDSG n.F.
                            und DS-GVO einhalten</p>
                    </>
                </ToggleBox>
                <ToggleBox title='Datenübermittlung in Drittstaaten'>
                    <>
                        <p>Durch die Verabschiedung der europäischen Datenschutz-Grundverordnung (DS-GVO) wurde eine
                            einheitliche Grundlage für den Datenschutz in Europa geschaffen. Ihre Daten werden daher
                            vorwiegend durch Unternehmen verarbeitet, für die DS-GVO Anwendung findet. Sollte doch die
                            Verarbeitung durch Dienste Dritter außerhalb der Europäischen Union oder des Europäischen
                            Wirtschaftsraums stattfinden, so müssen diese die besonderen Voraussetzungen der Art. 44 ff.
                            DS-GVO erfüllen. Das bedeutet, die Verarbeitung erfolgt aufgrund besonderer Garantien, wie
                            etwa die von der EU-Kommission offiziell anerkannte Feststellung eines der EU entsprechenden
                            Datenschutzniveaus oder der Beachtung offiziell anerkannter spezieller vertraglicher
                            Verpflichtungen, der so genannten „Standardvertragsklauseln“. Bei US-Unternehmen erfüllt die
                            Unterwerfung unter das sog. „Privacy-Shield“, dem Datenschutzabkommen zwischen der EU und
                            den USA, diese Voraussetzungen.</p>
                    </>
                </ToggleBox>
                <ToggleBox title='Löschung von Daten und Speicherdauer'>
                    <>
                        <p>Sofern nicht in dieser Datenschutzerklärung ausdrücklich angegeben, werden Ihre
                            personenbezogen Daten gelöscht oder gesperrt, sobald der Zweck für die Speicherung entfällt,
                            es sei denn deren weitere Aufbewahrung ist zu Beweiszwecken erforderlich oder dem stehen
                            gesetzliche Aufbewahrungspflichten entgegenstehen. Darunter fallen etwa handelsrechtliche
                            Aufbewahrungspflichten von Geschäftsbriefen nach § 257 Abs. 1 HGB (6 Jahre) sowie
                            steuerrechtliche Aufbewahrungspflichten nach § 147 Abs. 1 AO von Belegen (10 Jahre). Wenn
                            die vorgeschriebene Aufbewahrungsfrist abläuft, erfolgt eine Sperrung oder Löschung Ihrer
                            Daten, es sei denn die Speicherung ist weiterhin für einen Vertragsabschluss oder zur
                            Vertragserfüllung erforderlich.</p>
                    </>
                </ToggleBox>
                <ToggleBox title='Bestehen einer automatisierten Entscheidungsfindung'>
                    <>
                        <p>Wir setzen keine automatische Entscheidungsfindung oder ein Profiling ein.</p>
                    </>
                </ToggleBox>
                <ToggleBox title='Rechte der betroffenen Person'>
                    <>
                        <p>Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten

                            Soweit die Verarbeitung auf Ihrer Einwilligung gemäß Art. 6 Abs. 1 S. 1 lit. a), Art. 7
                            DS-GVO beruht, haben Sie das Recht, die Einwilligung jederzeit zu widerrufen. Die
                            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung wird
                            dadurch nicht berührt.

                            Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung gemäß
                            Art. 6 Abs. 1 S. 1 lit. f) DS-GVO stützen, können Sie Widerspruch gegen die Verarbeitung
                            einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines
                            Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung
                            der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um
                            Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns
                            durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die
                            Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen
                            unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung
                            fortführen.

                            Sie können der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und
                            Datenanalyse jederzeit widersprechen. Das Widerspruchsrecht können Sie kostenfrei ausüben.
                            Über Ihren Werbewiderspruch können Sie uns unter folgenden Kontaktdaten informieren:</p>
                        <div className='column-left'>
                            <div className='row-left'>
                                <div className='column'>
                                    <p>Hoffmann GmbH<br/> Von-Steinbeis-Str. 16a <br/>78476 Allensbach<br/> T +49 (0) 75
                                        33 | 9 49 69 0 <br/>F +49 (0) 75 33 | 9 49 69 69<br/></p>
                                    <table>
                                        <td>
                                            <tr>E-Mail:</tr>
                                            <tr>Homepage:</tr>
                                            <tr>Sitz der Gesellschaft:</tr>
                                            <tr>Registergericht:</tr>
                                            <tr>Registernummer:</tr>
                                            <tr>USt-IdNr:</tr>
                                            <tr>Geschäftsführer:</tr>
                                        </td>
                                        <td>
                                            <tr>kontakt(at)va-h.de</tr>
                                            <tr>www.va-h.de</tr>
                                            <tr>Allensbach</tr>
                                            <tr>Amtsgericht Freiburg</tr>
                                            <tr>HRB 382084</tr>
                                            <tr>DE264095132</tr>
                                            <tr>Urs Hoffmann</tr>
                                        </td>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <p>Recht auf Auskunft</p>
                        <p>
                            Sie haben ein Recht auf Auskunft über Ihre bei uns gespeicherten persönlichen Daten nach
                            Art. 15 DS-GVO. Dies beinhaltet insbesondere die Auskunft über die Verarbeitungszwecke, die
                            Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen Ihre
                            Daten offengelegt wurden oder werden, die geplante Speicherdauer, die Herkunft ihrer Daten,
                            sofern diese nicht direkt bei Ihnen erhoben wurden.</p>
                        <p>Recht auf Berichtigung</p>
                        <p>Sie haben ein Recht auf Berichtigung unrichtiger oder auf Vervollständigung richtiger Daten
                            nach Art. 16 DS-GVO.</p>
                        <p>Recht auf Löschung</p>
                        <p>Sie haben ein Recht auf Löschung Ihrer bei uns gespeicherten Daten nach Art. 17 DS-GVO, es
                            sei denn gesetzliche oder vertraglichen Aufbewahrungsfristen oder andere gesetzliche
                            Pflichten bzw. Rechte zur weiteren Speicherung stehen dieser entgegen.</p>
                        <p>Recht auf Einschränkung</p>
                        <p>Sie haben das Recht, eine Einschränkung bei der Verarbeitung Ihrer personenbezogenen Daten zu
                            verlangen, wenn eine der Voraussetzungen in Art. 18 Abs. 1 lit. a) bis d) DS-GVO erfüllt
                            ist:</p>
                        <ul>
                            <li>Wenn Sie die Richtigkeit der Sie betreffenden personenbezogenen für eine Dauer
                                bestreiten, die es dem Verantwortlichen ermöglicht, die Richtigkeit der
                                personenbezogenen Daten zu überprüfen;
                            </li>
                            <li>die Verarbeitung unrechtmäßig ist und Sie die Löschung der personenbezogenen Daten
                                ablehnen und stattdessen die Einschränkung der Nutzung der personenbezogenen Daten
                                verlangen;
                            </li>
                            <li>der Verantwortliche die personenbezogenen Daten für die Zwecke der Verarbeitung nicht
                                länger benötigt, Sie diese jedoch zur Geltendmachung, Ausübung oder Verteidigung von
                                Rechtsansprüchen benötigen, oder
                            </li>
                            <li>wenn Sie Widerspruch gegen die Verarbeitung gemäß Art. 21 Abs. 1 DS-GVO eingelegt haben
                                und noch nicht feststeht, ob die berechtigten Gründe des Verantwortlichen gegenüber
                                Ihren Gründen überwiegen.
                            </li>
                        </ul>
                        <p>Recht auf Datenübertragbarkeit</p>
                        <p>Sie haben ein Recht auf Datenübertragbarkeit nach Art. 20 DS-GVO, was bedeutet, dass Sie die
                            bei uns über Sie gespeicherten personenbezogenen Daten in einem strukturierten, gängigen und
                            maschinenlesbaren Format erhalten können oder die Übermittlung an einen anderen
                            Verantwortlichen verlangen können.</p>
                        <p>Recht auf Beschwerde</p>
                        <p>Sie haben ein Recht auf Beschwerde bei einer Aufsichtsbehörde. In der Regel können Sie sich
                            hierfür an die Aufsichtsbehörde insbesondere in dem Mitgliedstaat ihres Aufenthaltsorts,
                            ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes wenden.</p>
                    </>
                </ToggleBox>
                <ToggleBox title='Datensicherheit'>
                    <>
                        <p>Um alle personenbezogen Daten, die an uns übermittelt werden, zu schützen und um
                            sicherzustellen, dass die Datenschutzvorschriften von uns, aber auch unseren externen
                            Dienstleistern eingehalten werden, haben wir geeignete technische und organisatorische
                            Sicherheitsmaßnahmen getroffen. Deshalb werden unter anderem alle Daten zwischen Ihrem
                            Browser und unserem Server über eine sichere SSL-Verbindung verschlüsselt übertragen.</p>
                        <p>Stand: 31.03.2019</p>
                        <a href="https://www.juraforum.de/datenschutzerklaerung-muster/">Muster-Datenschutzerklärung von
                            JuraForum.de</a>
                    </>
                </ToggleBox>
            </div>
            </div>
        )
    }
}
