import React, {PureComponent} from 'react'
import {NavBarItem, NavBarItemLink, NavBarItemNewTab} from "../components/NavbarItem";

export const Unter = 'Unternehmen'
export const Anspr = 'Ansprechpartner'
export const Leist = 'Leistungen'
export const Partn = 'Partner'
//export const Newsn = 'Newsletter'


export interface INavProps {
    setElement: (element: string)=>void,
    active: string,
}

export interface INavState {
    toggle: boolean,
    active: undefined | string,
    ansprechElement: number,
    leisElement: number,
    newsElement: number,
    unterElement: number,
    parElement: number,
}

export class Nav extends PureComponent<INavProps, INavState>{
    state = {
        toggle: true,
        active: undefined,
        ansprechElement: 0,
        leisElement: 0,
        newsElement: 0,
        unterElement: 0,
        parElement: 0
    }

    componentDidMount(): void {
        window.addEventListener('scroll', this.scrolling)


        const ansprechElement = document.getElementById('Ansprechpartner')
        const leisElement = document.getElementById('Leistungen')
        const parElement = document.getElementById('Partner')
        //const newsElement = document.getElementById('Newsletter')
        const unterElement = document.getElementById('Unternehmen')


        if(ansprechElement && leisElement && unterElement && parElement){
            this.setState({
            unterElement: unterElement.offsetTop,
            ansprechElement: ansprechElement.offsetTop,
            leisElement: leisElement.offsetTop,
            parElement: parElement.offsetTop,
        })
        }

    }
    componentWillUnmount(): void {
        window.removeEventListener('scroll', this.scrolling)
    }

    scrolling = (event: any) => {
        const {active, unterElement, ansprechElement, leisElement, parElement,} = this.state
        const y = window.scrollY;
        const path = window.location.pathname.split('/');

            if (y >= unterElement && y < ansprechElement && active !== Unter && path[path.length - 1] === '') {
            this.setState({active: Unter})
            this.props.setElement('')
        } else if (y >= ansprechElement && y < leisElement && active !== Anspr && path[path.length - 1] === '') {
            this.setState({active: Anspr})
            this.props.setElement('')
        } else if (y >= leisElement && y < parElement && active !== Leist && path[path.length - 1] === '') {
            this.setState({active: Leist})
            this.props.setElement('')
        } else if (y >= parElement && y < leisElement + parElement && active !== Partn && path[path.length - 1] === '') {
            this.setState({active: Partn})
            this.props.setElement('')
        } else if (path[path.length - 1] !== '') {
            this.setState({active: undefined})
            // this.props.setElement('')


        }

    }

    render(): React.ReactNode {
        const {toggle, active} = this.state

        const toggleMode: string = toggle ? 'nav-close' : 'nav-open'

        return (
            <div className="nav">
                {/* LOGO */}
                <div className='mobile-landscape-logo'>
                    <img className='logo' alt="" src={'./images/logo2.jpg'}/>
                </div>
                {/* Address */}
                <div className='column1'>
                    <small>Von-Steinbeis-Straße 16a</small>
                    <small>78476 Allensbach</small>
                    <small>T +49 &nbsp;(0) 75 33 | 9 49 69 0</small>
                    <small>F +49 &nbsp;(0) 75 33 | 9 49 69 69</small>
                    <br/>
                    <small>Mo.-Do. 09.00 - 12.30 | 14.00 - 17.30</small>
                    <small>Freitags geschlossen</small>
                    <small>Termine nach Absprache</small>
                </div>
                <div onClick={()=> this.setState({toggle: !toggle})} className='burger-menu mobile'>
                    <div className='palets'/>
                    <div className='palets'/>
                    <div className='palets'/>
                </div>
                <div className={toggleMode}>
                {/* Main Menu */}
                <div className='main-nav'>
                    <NavBarItemLink to='' title='UNTERNEHMEN' className={`navBarItemDotted navBarItem  ${active === Unter ? 'active-nav' : ''}`} onClick={()=>{this.props.setElement('Unternehmen'); this.setState({active: Unter})}}/>
                    </div>
                    <NavBarItemLink to='' title='ANSPRECHPARTNER' className={`navBarItemDotted navBarItem  ${active === Anspr ? 'active-nav' : ''}`} onClick={()=>{this.props.setElement('Ansprechpartner'); this.setState({active: Anspr})}}/>
                    <NavBarItemLink to='' title='LEISTUNGEN' className={`navBarItemDotted navBarItem ${active === Leist ? 'active-nav' : ''}`} onClick={()=>{this.props.setElement('Leistungen'); this.setState({active: Leist})}}/>
                    <NavBarItemLink to='' title='PARTNER' className={`navBarItemDotted navBarItem  ${active === Partn ? 'active-nav' : ''}`} onClick={()=>{this.props.setElement('Partner'); this.setState({active: Partn})}}/>
               
                {/* Sub Menu */}
                <div className='sub-nav'>
                    <NavBarItemNewTab className='sub-nav-link navBarItem margin-bottom-10' title='Erstinformationen / Statusbezogene Informationen' link='https://va-h.de/Erstinformationen_Stand_08_2023.pdf'/>
                    <NavBarItem title='EINWILLIGUNG ZUR DATENVERARBEITUNG'/>
                    <NavBarItemNewTab className='sub-nav-link navBarItem ' title='Kapitalanlagen' link='https://va-h.de/Kapitalanlagen.pdf'/>
                    <NavBarItemNewTab className='sub-nav-link navBarItem ' title='Versicherungen' link='https://va-h.de/Versicherung.pdf'/>
                </div>
                {/* Kontakt/Impressum/Datenschutzerklärung Menu */}
                <div className='bottom-nav'>
                    <NavBarItemLink className='sub-nav-link navBarItem ' title='Kontakt' to='/Kontakt' onClick={()=>{this.setState({active: undefined})}}/>
                    <NavBarItemLink className='sub-nav-link navBarItem ' title='Impressum' to='/Impressum' onClick={()=>{this.setState({active: undefined})}}/>
                    <NavBarItemLink className='sub-nav-link navBarItem ' title='Datenschutzerklärung' to='/Datenschutzerklärung' onClick={()=>{this.setState({active: undefined})}}/>
                </div>
                </div>
            </div>
        )
    }
}