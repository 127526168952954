import React, {PureComponent} from 'react'
import {Route, BrowserRouter as Router} from 'react-router-dom'
import {Nav} from "./Nav";
import Versicherung from "./Versicherung";
import Kapitalanlagen from "./Kapitalanlagen";
import Kontakt from "./Kontakt";
import Impressum from "./Impressum";
import Home from "./Home";
import {Footer} from "./footer";
import Datenschutzerklärung from './Datenschutzerklärung';
import Photovoltaikanlagen from './Photovoltaikanlagen';

export interface IAppProps {

}

export interface IAppState {
    scrollToElement: string,
}


export class App extends PureComponent<IAppProps, IAppState> {

    state = {
        scrollToElement: '',
    }


    setElement = (active: string) => {
        this.setState({scrollToElement: active})
    }


    render(): React.ReactNode {

        return (
            <Router basename='/'>
                <div id='screen' className='full-screen'>
                    <div className='app-wrapper'>
                        <div className='nav-wrapper'>
                            <Nav setElement={this.setElement}
                                 active={this.state.scrollToElement}/>
                        </div>
                        <Route exact path="/"
                               component={() => <Home setElement={this.setElement}
                                                      scrollToElement={this.state.scrollToElement}/>}/>
                        <Route path="/Versicherung"
                               component={() => <Versicherung setElement={this.setElement}
                                                              scrollToElement={this.state.scrollToElement}/>}/>
                        <Route path="/Kapitalanlagen"
                               component={() => <Kapitalanlagen setElement={this.setElement}
                                                                scrollToElement={this.state.scrollToElement}/>}/>
                        <Route path="/Photovoltaikanlagen"
                               component={() => <Photovoltaikanlagen setElement={this.setElement}
                                                                scrollToElement={this.state.scrollToElement}/>}/>
                        <Route path="/Kontakt" component={Kontakt}/>
                        <Route path="/Impressum" component={Impressum}/>
                        <Route path="/Datenschutzerklärung" component={Datenschutzerklärung}/>
                    </div>
                    <Footer/>
                </div>
            </Router>
        );
    }
}
