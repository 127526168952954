import React, {PureComponent} from 'react';
import {Element} from 'react-scroll'
import {ContextBox} from "../../components/ContextBox";
import {ToggleBox} from "../../components/ToggleBox";
import {NavBarItemLink} from "../../components/NavbarItem";

export interface ILeistungenProps {
    id?: string,
    setElement: (value: string)=>void
}

export interface ILeistungenState {

}

export class Leistungen extends PureComponent<ILeistungenProps, ILeistungenState> {
    render(): React.ReactNode {

        return (
            <Element id='Leistungen'  name='Leistungen' >
                <div className='isDesktopShown leistung' >
             <ContextBox className='contextBox-gray box-0 mobile'>
            <h2>Leistungen</h2>
            </ContextBox>
            <div className='margin-top-scroll'></div>
            <div className='box-wrapper-0'>
                <div className='box-wrapper-one'>
                    <ContextBox className='contextBox-blue box-1 margin-top-20'>
                        <div className='column'>
                            <h3>Kapitalanlagen</h3>
                            <h3>Versicherungen</h3>
                            <h3>Betriebliche Vorsorgekonzepte</h3>
                            <h3>Dienstleistungen</h3>
                            <h3>Photovoltaikanlagen</h3>
                        </div>
                    </ContextBox>
                    <ContextBox className='contextBox-gray box-start-1'>
                        <h3>Kapitalanlagen</h3>
                        <p>Vermögensaufbau und Vermögenserhalt sind die Säulen des Wohlstands!</p>
                        <p>Das Angebot an Kapitalanlagen ist allerdings komplex. Wir analysieren im persönlichen Gespräch, was es zu beachten gilt, um ein Anlagekonzept zu erstellen, das auf die persönlichen Ziele und Wünsche unserer Kunden zugeschnitten ist.</p>
                        <ToggleBox title='Alle Anlageangebote anzeigen'>
                            <>
                                <p>Für weitere Infos, bitte die einzelnen Punkte anklicken.</p>
                                <ul>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Investmentfonds' className='leistungen-link' onClick={()=>{this.props.setElement('Investmentfonds')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Bank Produkte' className='leistungen-link' onClick={()=>{this.props.setElement('Bank Produkte')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Standardisierte Vermögensverwaltung' className='leistungen-link' onClick={()=>{this.props.setElement('Standardisierte Vermögensverwaltung')}}/></li>
                                </ul>
                                <span>Direktinvestments</span>
                                <ul>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Solvium Container' className='leistungen-link' onClick={()=>{this.props.setElement('Solvium Container')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Solvium Wechselkoffer' className='leistungen-link' onClick={()=>{this.props.setElement('Solvium Wechselkoffer')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Thamm & Partner' className='leistungen-link' onClick={()=>{this.props.setElement('Thamm & Partner')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Deutsche Finance Group AG' className='leistungen-link' onClick={()=>{this.props.setElement('Deutsche Finance Group AG')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='RWB Private Capital Emissionshaus AG' className='leistungen-link' onClick={()=>{this.props.setElement('RWB Private Capital Emissionshaus AG')}}/></li>
                                </ul>
                            </>
                        </ToggleBox>
                    </ContextBox>
                    <ContextBox className='contextBox-gray box-end-1'>
                        <h3>Betriebliche Vorsorgekonzepte</h3>
                        <p>Mitarbeiter Bindung und Motivation ist das Zauberwort in Zeiten des Fachkräftemangels.</p>
                        <p>Das Gesetz zur Verbesserung der Betrieblichen Altersvorsorge (Betr. AVG) sieht verschiedene Durchführungswege für Unternehmen und Gewerbetreibende vor. Hier gilt es, den für das jeweilige Unternehmen individuell bestmöglichen Weg zu wählen, um sowohl für das Unternehmen als auch für den einzelnen Mitarbeiter einen deutlichen Mehrwert zu erzielen.</p>
                    </ContextBox>
                    <ContextBox className='contextBox-blue box-end-2'>
                    <h2>Hinweis zur Tapingpflicht</h2>
                    <p>Bitte beachten Sie, dass wir ab dem 01.08.2020 aufgrund der Aufzeichnungs-Pflicht (Taping) gem. §
                        18a FinVermV keine telefonischen Beratungen zu neuen Finanzanlageprodukten vornehmen können.
                        Selbstverständlich vereinbaren wir gerne einen persönlichen Termin oder eine Video Konferenz
                        (mit Aufzeichnung), um Ihr individuelles Finanzanlagekonzept mit Ihnen zu erstellen.</p>
                    </ContextBox>
                    </div>
                <div className='box-wrapper-two'>
                <ContextBox className='contextBox-gray box-start'>
                            <h3>Versicherungen</h3>
                            <p>Risikovorsorge ist wichtig!</p>
                            <p>Welche Versicherungen allerdings wichtig oder unter Umständen sogar unverzichtbar sind, erarbeiten wir im persönlichen Gespräch um einen optimalen und individuellen Versicherungsschutz zu gewährleisten.</p>
                            <ToggleBox title='Alle Versicherungen anzeigen'>
                                <>
                                <p>Für weitere Infos, bitte die einzelnen Punkte anklicken.</p>
                                    <ul>
                                        <li><NavBarItemLink to='Versicherung' title='Krankenversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Krankenversicherung')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Betriebliche Krankenversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Betriebliche Krankenversicherungen bKV')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Krankenzusatzversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Krankenzusatzversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Pflegeversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Pflegeversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Schwere Krankheiten Vorsorge' className='leistungen-link' onClick={()=>{this.props.setElement('Schwere Krankheiten Vorsorge')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Grundfähigkeitsabsicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Grundfähigkeitsabsicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Unfallversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Unfallversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Berufsunfähigkeitsversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Berufsunfähigkeitsversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Risikolebensversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Risikolebensversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Lebens- und Rentenversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Lebens- und Rentenversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Riester- /Rürupvorsorge' className='leistungen-link' onClick={()=>{this.props.setElement('Riester- /Rürupvorsorge')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Sachversicherungen für Privat und Gewebe' className='leistungen-link' onClick={()=>{this.props.setElement('Sachversicherungen für Privat und Gewerbe')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Vermögensversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Vermögensversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Wohngebäudeversicherung' className='leistungen-link' onClick={()=>{this.props.setElement('Wohngebäudeversicherung')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='KFZ-Versicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('KFZ-Versicherungen')}}/></li>
										<li><NavBarItemLink to='Versicherung' title='Zahnzusatzversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Zahnzusatzversicherungen')}}/></li>
                                    </ul>
                                </>
                            </ToggleBox>
                        </ContextBox>
                        <ContextBox className='contextBox-gray box-end'>
                            <h3>Dienstleistungen</h3>
                            <ul>
                                <li>Analyse der Finanz- und Versicherungssituation (Status Quo)</li>
                                <li>Erstellung eines individuellen Finanz- und Versicherungskonzeptes (Zukunftsstrategie)</li>
                                <li>Betreuung und gegebenenfalls Anpassung der gewählten Strategie</li>
                            </ul>
                            <p>Preis-Leistungsvergleich bestehender Versicherungen</p>
                            <p>Beitragsoptimierung der privaten Krankenversicherung</p>
                            <p>Grenzgängerinformation</p>
                        </ContextBox>
                        <ContextBox className='contextBox-gray box-end'>
                            <h3>Photovoltaikanlagen</h3>
                            <p>Investieren Sie in die Zukunft und nutzen Sie so die zahlreichen Vorteile des Kaufs einer Photovoltaik-Einzelanlage innerhalb eines Solarparks.</p>
                            <p>Sie können sich das Vorgehen wie die Aufteilung eines Mehrfamilienhauses in einzelne Eigentumswohnungen vorstellen – Sie erwerben direktes Eigentum an einer Solarstromanlage</p>
                            <span>Ihre Vorteile: </span>
                            <ul>
                                <li><NavBarItemLink to='Photovoltaikanlagen' title='Investition in eine grüne Zukunft' className='leistungen-link-green' onClick={()=>{this.props.setElement('Investition in eine grüne Zukunft')}}/></li>
                                <li><NavBarItemLink to='Photovoltaikanlagen' title='Sichere Einnahmen durch das Erneuerbare-Energien-Gesetz (EEG)' className='leistungen-link-green' onClick={()=>{this.props.setElement('Sichere Einnahmen durch das Erneuerbare-Energien-Gesetz (EEG)')}}/></li>
								<li><NavBarItemLink to='Photovoltaikanlagen' title='Umfangreiche steuerliche Vorteile' className='leistungen-link-green' onClick={()=>{this.props.setElement('Umfangreiche steuerliche Vorteile')}}/></li>
                            </ul>
                            <p>Gerne erstellen wir Ihnen ein individuelles Angebot mit Beispielrechnungen – sprechen Sie uns einfach an!</p>
                        </ContextBox>
                </div>
            </div>
            </div>
            <div className='isMobileShown leistung'>
             <ContextBox className='contextBox-gray box-0 mobile'>
            <h2>Leistungen</h2>
            </ContextBox>
            <div className='margin-top-scroll'></div>
            <div className='box-wrapper-0'>
                <div className='box-wrapper-one'>
                    <ContextBox className='contextBox-blue box-1 margin-top-20'>
                        <div className='column'>
                            <h3>Kapitalanlagen</h3>
                            <h3>Versicherungen</h3>
                            <h3>Betriebliche Vorsorgekonzepte</h3>
                            <h3>Dienstleistungen</h3>
                            <h3>Photovoltaikanlagen</h3>
                        </div>
                    </ContextBox>
                    <ContextBox className='contextBox-gray box-start-1'>
                        <h3>Kapitalanlagen</h3>
                        <p>Vermögensaufbau und Vermögenserhalt sind die Säulen des Wohlstands!</p>
                        <p>Das Angebot an Kapitalanlagen ist allerdings komplex. Wir analysieren im persönlichen Gespräch, was es zu beachten gilt, um ein Anlagekonzept zu erstellen, das auf die persönlichen Ziele und Wünsche unserer Kunden zugeschnitten ist.</p>
                        <ToggleBox title='Alle Anlageangebote anzeigen'>
                            <>
                                <p>Für weitere Infos, bitte die einzelnen Punkte anklicken.</p>
                                <ul>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Investmentfonds' className='leistungen-link' onClick={()=>{this.props.setElement('Investmentfonds')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Bank Produkte' className='leistungen-link' onClick={()=>{this.props.setElement('Bank Produkte')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Standardisierte Vermögensverwaltung' className='leistungen-link' onClick={()=>{this.props.setElement('Standardisierte Vermögensverwaltung')}}/></li>
                                </ul>
                                <span>Direktinvestments</span>
                                <ul>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Solvium Container' className='leistungen-link' onClick={()=>{this.props.setElement('Solvium Container')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Solvium Wechselkoffer' className='leistungen-link' onClick={()=>{this.props.setElement('Solvium Wechselkoffer')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Thamm & Partner' className='leistungen-link' onClick={()=>{this.props.setElement('Thamm & Partner')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='Deutsche Finance Group AG' className='leistungen-link' onClick={()=>{this.props.setElement('Deutsche Finance Group AG')}}/></li>
                                    <li><NavBarItemLink to='Kapitalanlagen' title='RWB Private Capital Emissionshaus AG' className='leistungen-link' onClick={()=>{this.props.setElement('RWB Private Capital Emissionshaus AG')}}/></li>
                                </ul>
                            </>
                        </ToggleBox>
                    </ContextBox>
                    <ContextBox className='contextBox-gray box-start'>
                            <h3>Versicherungen</h3>
                            <p>Risikovorsorge ist wichtig!</p>
                            <p>Welche Versicherungen allerdings wichtig oder unter Umständen sogar unverzichtbar sind, erarbeiten wir im persönlichen Gespräch um einen optimalen und individuellen Versicherungsschutz zu gewährleisten.</p>
                            <ToggleBox title='Alle Versicherungen anzeigen'>
                                <>
                                <p>Für weitere Infos, bitte die einzelnen Punkte anklicken.</p>
                                    <ul>
                                        <li><NavBarItemLink to='Versicherung' title='Krankenversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Krankenversicherung')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Betriebliche Krankenversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Betriebliche Krankenversicherungen bKV')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Krankenzusatzversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Krankenzusatzversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Pflegeversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Pflegeversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Schwere Krankheiten Vorsorge' className='leistungen-link' onClick={()=>{this.props.setElement('Schwere Krankheiten Vorsorge')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Grundfähigkeitsabsicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Grundfähigkeitsabsicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Unfallversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Unfallversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Berufsunfähigkeitsversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Berufsunfähigkeitsversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Risikolebensversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Risikolebensversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Lebens- und Rentenversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Lebens- und Rentenversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Riester- /Rürupvorsorge' className='leistungen-link' onClick={()=>{this.props.setElement('Riester- /Rürupvorsorge')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Sachversicherungen für Privat und Gewebe' className='leistungen-link' onClick={()=>{this.props.setElement('Sachversicherungen für Privat und Gewerbe')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Vermögensversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Vermögensversicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Wohngebäudeversicherung' className='leistungen-link' onClick={()=>{this.props.setElement('Wohngebäudeversicherung')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='KFZ-Versicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('KFZ-Versicherungen')}}/></li>
                                        <li><NavBarItemLink to='Versicherung' title='Zahnzusatzversicherungen' className='leistungen-link' onClick={()=>{this.props.setElement('Zahnzusatzversicherungen')}}/></li>
                                    </ul>
                                </>
                            </ToggleBox>
                        </ContextBox>
                    </div>
                <div className='box-wrapper-two'>
                        <ContextBox className='contextBox-gray box-end-1'>
                        <h3>Betriebliche Vorsorgekonzepte</h3>
                        <p>Mitarbeiter Bindung und Motivation ist das Zauberwort in Zeiten des Fachkräftemangels.</p>
                        <p>Das Gesetz zur Verbesserung der Betrieblichen Altersvorsorge (Betr. AVG) sieht verschiedene Durchführungswege für Unternehmen und Gewerbetreibende vor. Hier gilt es, den für das jeweilige Unternehmen individuell bestmöglichen Weg zu wählen, um sowohl für das Unternehmen als auch für den einzelnen Mitarbeiter einen deutlichen Mehrwert zu erzielen.</p>
                    </ContextBox>
                        <ContextBox className='contextBox-gray box-end-2'>
                            <h3>Dienstleistungen</h3>
                            <ul>
                                <li>Analyse der Finanz- und Versicherungssituation (Status Quo)</li>
                                <li>Erstellung eines individuellen Finanz- und Versicherungskonzeptes (Zukunftsstrategie)</li>
                                <li>Betreuung und gegebenenfalls Anpassung der gewählten Strategie</li>
                            </ul>
                            <p>Preis-Leistungsvergleich bestehender Versicherungen</p>
                            <p>Beitragsoptimierung der privaten Krankenversicherung</p>
                            <p>Grenzgängerinformation</p>
                        </ContextBox>
                        <ContextBox className='contextBox-gray box-end-3'>
                            <h3>Photovoltaikanlagen</h3>
                            <p>Investieren Sie in die Zukunft und nutzen Sie so die zahlreichen Vorteile des Kaufs einer Photovoltaik-Einzelanlage innerhalb eines Solarparks.</p>
                            <p>Sie können sich das Vorgehen wie die Aufteilung eines Mehrfamilienhauses in einzelne Eigentumswohnungen vorstellen – Sie erwerben direktes Eigentum an einer Solarstromanlage</p>
                            <span>Ihre Vorteile: </span>
                            <ul>
                                <li><NavBarItemLink to='Photovoltaikanlagen' title='Investition in eine grüne Zukunft' className='leistungen-link-green' onClick={()=>{this.props.setElement('Investition in eine grüne Zukunft')}}/></li>
                                <li><NavBarItemLink to='Photovoltaikanlagen' title='Sichere Einnahmen durch das Erneuerbare-Energien-Gesetz (EEG)' className='leistungen-link-green' onClick={()=>{this.props.setElement('Sichere Einnahmen durch das Erneuerbare-Energien-Gesetz (EEG)')}}/></li>
								<li><NavBarItemLink to='Photovoltaikanlagen' title='Umfangreiche steuerliche Vorteile' className='leistungen-link-green' onClick={()=>{this.props.setElement('Umfangreiche steuerliche Vorteile')}}/></li>
                            </ul>
                            <p>Gerne erstellen wir Ihnen ein individuelles Angebot mit Beispielrechnungen – sprechen Sie uns einfach an!</p>
                        </ContextBox>
                        <ContextBox className='contextBox-blue box-end-2'>
                    <h2>Hinweis zur Tapingpflicht</h2>
                    <p>Bitte beachten Sie, dass wir ab dem 01.08.2020 aufgrund der Aufzeichnungs-Pflicht (Taping) gem. §
                        18a FinVermV keine telefonischen Beratungen zu neuen Finanzanlageprodukten vornehmen können.
                        Selbstverständlich vereinbaren wir gerne einen persönlichen Termin oder eine Video Konferenz
                        (mit Aufzeichnung), um Ihr individuelles Finanzanlagekonzept mit Ihnen zu erstellen.</p>
                    </ContextBox>
                </div>
            </div>
            </div>
            </Element>
        );
    }
}
