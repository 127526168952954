import React, {PureComponent} from 'react'
import {Element} from 'react-scroll'
import {ContextBox} from '../../components/ContextBox'

interface IUnternehmen {
    id?: string,
}

export default class Unternehmen extends PureComponent<IUnternehmen, {}> {


    render(): React.ReactNode {
        return (
            <Element id='Unternehmen' name='Unternehmen'>
                <img className='title-picture' alt="" src={'./images/zugeschnitten.jpg'}/>
                <ContextBox className='contextBox-gray mobile'><h2>Unternehmen</h2></ContextBox>
                <div className='unternehmen'>
                    <ContextBox className='contextBox-shadow box-one'>
                        <>
                            <h2>Die Hoffmann GmbH</h2>
                            <h3>Der Spezialist für Finanz- und Versicherungskonzepte</h3>
                        </>
                    </ContextBox>
                    <ContextBox className='contextBox-blue box-two'>
                        <h2>Philosophie</h2>
                        <p>Wir sind ein Finanz- und Versicherungsmakler mit einem hohen Anspruch an unsere eigene
                            Leistung. Die unabhängige und individuelle Beratung unserer Kunden steht im Vordergrund.</p>
                    </ContextBox>
                    <ContextBox className='contextBox-gray box-three'>
                        <>
                            <h2>Unabhängig</h2>
                            <p>Unabhängig bedeutet für uns – niemandem verpflichtet zu sein. Keinen Banken und keinen
                                Versicherungen. Unser Angebot wählen wir aus einer Vielzahl von Anbietern aus, wobei wir
                                größten Wert auf Qualität legen.</p>
                        </>
                    </ContextBox>
                    <ContextBox className='contextBox-gray box-four'>
                        <>
                            <h2>Individuell</h2>
                            <p>Die individuelle Lebenssituation unserer Kunden ist die Grundlage der Zusammenarbeit. Nur
                                wenn wir die Bedürfnisse kennen, können wir Verantwortung übernehmen und gemeinsam
                                Entscheidungen treffen.</p>
                        </>
                    </ContextBox>
                </div>
            </Element>
        )
    }
}
