import React from 'react'
import {NavBarItemLink,} from "../components/NavbarItem";

export function Footer() {
    return (
        <div className='column-center fix-bottom'>
            <div className='row-center background-lightgray'>
                <div className='column-center-footer'>
                    <span>Hoffmann GmbH - Finanz- und Versicherungsmakler · Von-Steinbeis-Straße 16a · 78476 Allensbach</span>
                    <span>E-Mail: kontakt(at)va-h.de · Web: www.va-h.de</span>
                </div>
            </div>
            <div className='column-center background-gray'>
                <span>Copyright 2019 Hoffmann GmbH</span>
                <span>Design by Tibold Informatik</span>
                <div className='row-center'>
                    <NavBarItemLink className='footer-link' title='Kontakt' to='Kontakt'/>
                    <NavBarItemLink className='footer-link' title='/ Impressum' to='Impressum'/>
                    <NavBarItemLink className='footer-link' title='/ Datenschutzerklärung' to='Datenschutzerklärung'/>
                </div>
            </div>
        </div>
    )
}
