import React from 'react'

export interface IContextBox {
    children?: any,
    className?: string
}

export function ContextBox({children, className}: IContextBox) {
    return (
        <div className={`contextBox ${className}`}>
            {children}
        </div>
    )
}
