import React, {PureComponent} from 'react';
import {ToggleBox} from "../components/ToggleBox";
import {Element} from 'react-scroll'
import {Link} from 'react-router-dom'
import { Leist } from './Nav';
import {scroller} from "react-scroll/modules";

export default class Photovoltaikanlagen extends PureComponent<{ setElement: (value: string)=>void, scrollToElement: string }, {}> {

    componentDidMount(): void {
        const {scrollToElement} = this.props

        if(scrollToElement){
            scroller.scrollTo(scrollToElement, {})
        }
    }

    componentDidUpdate(prevProps: Readonly<{ setElement: (value: string) => void; scrollToElement: string }>, prevState: Readonly<{}>, snapshot?: any): void {

        if(this.props.scrollToElement !== prevProps.scrollToElement){
            scroller.scrollTo(this.props.scrollToElement,{})
        }
    }


    render(): React.ReactNode {

        return (
            <div className='screen-container space-to-top'>
                <Element name={'Investition in eine grüne Zukunft'}>
                    <ToggleBox isOpen={this.props.scrollToElement === 'Investition in eine grüne Zukunft'}
                               title='Ihre Investition in eine grüne Zukunft'>
                        <p>Elektro-Mobilität, Smart Grid, Stromspeicher – das sind die Zukunftsthemen für eine grüne Energieversorgung unserer Städte und Haushalte. 
                            Mit einer Investition profitieren Sie nicht nur von stabilen finanziellen Erträgen, sondern beteiligen sich auch aktiv an einem der größten Projekte unserer Gesellschaft: der Energiewende</p>
                    </ToggleBox>
                </Element>
                <Element name={'Sichere Einnahmen durch das Erneuerbare-Energien-Gesetz (EEG)'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Sichere Einnahmen durch das Erneuerbare-Energien-Gesetz (EEG)'}
                               title='Sichere Einnahmen durch das EEG'>
                    <>
                        <p>Die Erlöse eines Photovoltaikkraftwerkes werden durch das Erneuerbare-Energien-Gesetz (EEG) geregelt. So ist die Abnahme des gesamten über 20 Jahre produzierten Stroms zu einem festen gleichbleibenden Preis garantiert. 
                            Durch diese garantierten Einnahmen und der kalkulierten Sonneneinstrahlung kann der voraussichtliche Gewinn aus einem Investment in ein Photovoltaikkraftwerk sehr präzise prognostiziert werden.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Umfangreiche steuerliche Vorteile'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Umfangreiche steuerliche Vorteile'}
                               title='Nutzen Sie umfangreiche steuerliche Vorteile'>
                    <>
                        <p>Neben der Langfristigkeit und den durch das Erneuerbare-Energien-Gesetz garantierten Einnahmen sprechen vor allem auch steuerliche Anreize für ein Investment in eine Photovoltaikanlage. 
                            So machen der Investitionsabzugsbetrag (IAB) und Sonderabschreibungen den Kauf einer Photovoltaikanlage steuerlich besonders attraktiv. </p>
                            <p>Dabei nutzen Sie verschiedene Möglichkeiten der steuerlichen Abschreibung und können sich so ca. 20% des Investments als Steuererstattung zurückholen.
                                 Bei einem Investment von beispielsweise 100.000 Euro können Sie sich also bis zu 20.000 Euro Steuererstattung sichern!</p>
                    </>
                </ToggleBox>
                </Element>
                <div className="zurück-container">
                    <div className="zurück-knopf" onClick={()=>{this.props.setElement('Leistungen'); this.setState({active: Leist})}}><Link to='/' >{"zurück zu Leistungen"}</Link></div>
                </div>
            </div>
        )
    }
}
