import React, {PureComponent} from 'react';
import {ToggleBox} from "../components/ToggleBox";
import {Element} from 'react-scroll'
import {Link} from 'react-router-dom'
import { Leist } from './Nav';
import {scroller} from "react-scroll/modules";

export default class Versicherung extends PureComponent<{ setElement: (value: string)=>void, scrollToElement: string }, {}> {

    componentDidMount(): void {
        const {scrollToElement} = this.props

        if(scrollToElement){
            scroller.scrollTo(scrollToElement, {})
        }
    }

    componentDidUpdate(prevProps: Readonly<{ setElement: (value: string) => void; scrollToElement: string }>, prevState: Readonly<{}>, snapshot?: any): void {

        if(this.props.scrollToElement !== prevProps.scrollToElement){
            scroller.scrollTo(this.props.scrollToElement,{})
        }
    }


    render(): React.ReactNode {

        return (
            <div className='screen-container space-to-top'>
                <Element name={'Krankenversicherung'}>
                    <ToggleBox isOpen={this.props.scrollToElement === 'Krankenversicherung'}
                               title='Krankenversicherung'>
                        <p>Ob private oder gesetzliche Krankenkasse – ohne geht’s nicht. Als Teil des deutschen
                            Sozialversicherungssystems und des Gesundheitssystems ist die Krankenversicherung eine
                            gesetzlich vorgeschriebene Absicherung für die Heilbehandlungskosten im Krankheitsfall, bei
                            Schwangerschaften und ggf. bei Unfällen.</p>
                    </ToggleBox>
                </Element>
                <Element name={'Betriebliche Krankenversicherungen bKV'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Betriebliche Krankenversicherungen bKV'}
                               title='Betriebliche Krankenversicherungen bKV'>
                    <>
                        <p>Die betriebliche Krankenversicherung stellt sowohl für den Arbeitgeber als auch für die
                            Arbeitnehmer eine klassische Win-Win-Situation dar. Die betriebliche Krankenversicherung ist
                            das optimale Instrument, begehrte Fachkräfte an das Unternehmen zu binden und gleichzeitig
                            Steuern zu sparen. Die Mitarbeiter wiederum erfreuen sich an stark verbesserten
                            Gesundheitsleistungen, die ihnen die gesetzlichen Krankenkassen nicht mehr bieten können,
                            auch für die Angehörigen und Familien der versicherten Arbeitnehmer.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Krankenzusatzversicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Krankenzusatzversicherungen'}
                               title='Krankenzusatzversicherungen'>
                    <>
                        <p>In Zeiten einer immer geringer werdenden Abdeckung von Seiten der gesetzlichen Krankenkasse,
                            ist eine Krankenzusatzversicherung von zunehmend größerer Bedeutung bei Zuzahlungen für
                            Zahnarztbehandlungen, Heilpraktiker und alternativen Heilmethoden, Sehhilfen oder
                            stationären Krankenhausaufenthalten.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Pflegeversicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Pflegeversicherungen'}
                               title='Pflegeversicherungen'>
                    <>
                        <p>Aufgrund des demografischen Wandels reichen die Leistungen der gesetzlichen
                            Pflegeversicherung nicht aus. Eine zusätzliche Private Pflegeversicherung ist eine
                            Möglichkeit, auch im Pflegefall alle Kosten problemlos tragen zu können, ohne die Familie zu
                            belasten oder sogar Eigentum verkaufen zu müssen. Zusätzlich gibt es die Möglichkeit
                            staatlicher Förderung.</p>
                            <video width="750" height="500" controls >
                                <source src="http://www.va-h.de/pflegefilm.mp4" type="video/mp4"/>
                                </video>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Schwere Krankheiten Vorsorge'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Schwere Krankheiten Vorsorge'}
                               title='Schwere Krankheiten Vorsorge'>
                    <>
                        <p>Der Eintritt einer schweren Krankheit ist nicht vorhersehbar und trifft die meisten Menschen
                            aus dem Nichts und völlig unvorbereitet. Die Schwere Krankheiten Vorsorge mildert
                            finanzielle Sorgen, die z.B. auf Grund eines Verdienstausfalls oder teurer Behandlungen
                            entstehen können.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Grundfähigkeitsabsicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Grundfähigkeitsabsicherungen'}
                               title='Grundfähigkeitsabsicherungen'>
                    <>
                        <p>Mit der Grundfähigkeitsversicherung sichern Sie sich gegen die finanziellen Folgen ab, die
                            ein Verlust von Fähigkeiten wie Sehen, Sprechen oder Hören mit sich bringen kann. Zusätzlich
                            bietet sie eine wichtige Absicherung ab dem Erreichen der Pflegestufe 1 oder bei Hilfebedarf
                            bei einer von vier Tätigkeiten des täglichen Lebens.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Unfallversicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Unfallversicherungen'}
                               title='Unfallversicherungen'>
                    <>
                        <p>Wenn Sie sich gegen die wirtschaftlichen Folgen eines Unfalls absichern möchten. Von einer
                            Sekunde zur anderen kann sich Ihr Leben durch einen Unfall einschneidend verändern. Sind die
                            körperlichen Schäden schon schlimm genug, wird es besonders tragisch, wenn dazu auch noch
                            finanzielle Nöte kommen, weil z. B. Ihre Wohnung oder Ihr Wagen behindertengerecht umgebaut
                            werden müssen. Oder Sie als Selbstständiger herbe Einkommenseinbußen überbrücken müssen.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Berufsunfähigkeitsversicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Berufsunfähigkeitsversicherungen'}
                               title='Berufsunfähigkeitsversicherungen'>
                    <>
                        <p>Die Berufsunfähigkeitsversicherung (BU) ist eine der wichtigsten Versicherungen überhaupt.
                            Denn wer nicht mehr arbeiten gehen kann, dem fehlt das Einkommen. Der Gesetzgeber bietet für
                            diesen Fall zwar eine Erwerbsminderungsrente für Arbeitnehmer, doch mit dieser lässt sich
                            der Lebensstandard nicht halten – schlimmstenfalls droht sogar der Verlust des bisherigen
                            Lebensstandards.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Risikolebensversicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Risikolebensversicherungen'}
                               title='Risikolebensversicherungen'>
                    <>
                        <p>Wenn der Versorger einer Familie stirbt, hinterlässt er zahlreiche finanzielle
                            Verpflichtungen. Belastungen, die nun die Angehörigen schultern müssen – mit einer geringen
                            Witwen/Witwer- oder Waisenrente oder ganz ohne staatliche Unterstützung. Eine
                            Risikolebensversicherung bietet eine Möglichkeit, Hinterbliebene für den Fall des Todes
                            abzusichern.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Lebens- und Rentenversicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Lebens- und Rentenversicherungen'}
                               title='Lebens- und Rentenversicherungen'>
                    <>
                        <p>Wer für sein Alter vorsorgen will, muss sich häufig 20, 30 Jahre im Voraus festlegen. Doch
                            wer weiß, wie sich sein Leben entwickeln wird? Wir setzen deshalb auf
                            Altersvorsorgelösungen, die Ihnen maximale Flexibilität bieten.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Riester- /Rürupvorsorge'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Riester- /Rürupvorsorge'}
                    title='Riester- /Rürupvorsorge'>
                    <>
                        <p>Die Riester- und auch die Rürup Rente gehören zu den staatlich geförderten
                            Altersvorsorgeprodukten. Sie unterscheiden sich hinsichtlich der Förderung und Auszahlung
                            als auch bei den Zugangsvoraussetzungen. Personen, die in den Genuss beider Produkte kommen
                            können, sollten stets abwägen, welche Variante für sie passender ist.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Sachversicherungen für Privat und Gewerbe'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Sachversicherungen für Privat und Gewerbe'}
                               title='Sachversicherungen für Privat und Gewerbe'>
                    <>
                        <p>Um bestimmte Sachen – beweglich oder unbeweglich – zu versichern, benötigt man eine geeignete
                            Sachversicherung. Die Sachversicherung leistet für den Fall, dass eine Sache – bzw. deren
                            finanzieller Wert – im Falle der Beschädigung, Zerstörung oder des Verlustes ausgeglichen
                            wird. Beispiel: Hausratversicherung (privat) bzw. Inhaltsversicherung (Gewerbe).</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Vermögensversicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Vermögensversicherungen'}
                               title='Vermögensversicherungen'>
                    <>
                        <p>Die private Haftpflichtversicherung ist die wohl wichtigste Versicherung für jedermann.
                            Der Grund dafür liegt auf der Hand. Wer einem anderen einen Schaden zufügt, haftet dafür im
                            Zweifel unbegrenzt und mit seinem gesamten Privatvermögen. Weitere Vermögensversicherungen
                            sind u.a. die Rechtsschutzversicherung, die Vermögensschadenhaftpflicht (für beratende
                            Berufe wie Steuerberater, Finanzberater und Anwälte) oder die Berufshaftpflicht.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'Wohngebäudeversicherung'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'Wohngebäudeversicherung'}
                               title='Wohngebäudeversicherung'>
                    <>
                        <p>Wenn Sie ein eigenes Haus oder eine Eigentumswohnung besitzen ist eine
                            Wohngebäudeversicherung unerlässlich. Schäden an Wohngebäuden gehen oft „richtig ins Geld“
                            oder im Extremfall droht der Totalverlust. Die Wohngebäudeversicherung deckt in der Regel
                            die Gefahren Feuer, Leitungswasser, Sturm und Hagelschäden ab. Diese Risikoabdeckung kann
                            individuell ergänzt werden, z.B. für Elementarschäden.</p>
                    </>
                </ToggleBox>
                </Element>
                <Element name={'KFZ-Versicherungen'}>
                <ToggleBox isOpen={this.props.scrollToElement === 'KFZ-Versicherungen'}
                               title='KFZ-Versicherungen'>
                    <p>Jeder Halter eines Kraftfahrzeuges haftet für jeden Schaden der durch das Fahrzeug entstehen
                        kann. Man spricht hier von Gefährdungshaftung. Geregelt ist dies in der
                        Straßenverkehrsordnung und dem Pflichtversicherungsgesetz. Die Versicherung eines Fahrzeuges
                        ist also nicht freiwillig, sondern zählt zu den Pflichtversicherungen. Da die Unfallgefahr
                        auch abhängig ist von der eigenen Fahrpraxis, dem Fahrzeugtyp und der Region, in der das
                        Fahrzeug hauptsächlich unterwegs ist, haben die Versicherer zur Beitragsermittlung ein
                        komplexes Verfahren entwickelt. Es setzt sich zusammen aus der Regionalklasse, der Typklasse
                        und der Schadenfreiheitsklasse.</p>
                </ToggleBox>
                </Element>
                <Element name={'Zahnzusatzversicherungen'}>
                    <ToggleBox isOpen={this.props.scrollToElement === 'Zahnzusatzversicherungen'}
                               title='Zahnzusatzversicherungen'>
                        <p>Sie möchten auch als gesetzlich Versicherter von einer Top-Versorgung beim Zahnarzt profitieren? Professionelle Zahnpflege und hochwertiger Zahnersatz werden immer mehr zur Privatsache. Unsere Zahnzusatztarife schützen Sie vor hohen Eigenbeteiligungen.</p>
                        <a href="https://onlineabschluss.universa.de/zahnzusatz?kk=XH5yYzQ2Nz8TMCpUa2VCMC0mOD4lFw%3d%3d">Hier gehts zum Beitragsrechner</a>
                    </ToggleBox>
                </Element>
                <div className="zurück-container">
                    <div className="zurück-knopf" onClick={()=>{this.props.setElement('Leistungen'); this.setState({active: Leist})}}><Link to='/' >{"zurück zu Leistungen"}</Link></div>
                </div>
            </div>
        )
    }
}
