import React, {PureComponent} from 'react';
import {ToggleBox} from "../components/ToggleBox";
import {Element} from "react-scroll";
import {Link} from 'react-router-dom'
import { Leist } from './Nav';
import {scroller} from "react-scroll/modules";

export default class Kapitalanlagen extends PureComponent<{ scrollToElement: string, setElement: (value: string)=>void }, {}> {
    componentDidMount(): void {
        const {scrollToElement} = this.props

        if(scrollToElement){
            scroller.scrollTo(scrollToElement, {})
        }
    }

    componentDidUpdate(prevProps: Readonly<{ setElement: (value: string) => void; scrollToElement: string }>, prevState: Readonly<{}>, snapshot?: any): void {

        if(this.props.scrollToElement !== prevProps.scrollToElement){
            scroller.scrollTo(this.props.scrollToElement,{})
        }
    }

    render(): React.ReactNode {
        return (
            <div className='screen-container space-to-top'>
                <Element name='Investmentfonds'>
                    <ToggleBox title='Investmentfonds' isOpen={this.props.scrollToElement === 'Investmentfonds'}>
                        <p>Fonds –als Kapitalsammelstelle– bieten Anlegern einen direkten Zugang zu den internationalen
                            Kapitalmärkten. Schon mit kleinen Beträgen können Sie an der Wertentwicklung von
                            Wertpapieren
                            und Immobilien teilhaben.</p>
                        <div className='box-image'>
                        <img className='investment-picture' alt="" src={'./images/investment.jpg'}/>
                        </div>
                        </ToggleBox>
                </Element>
                <Element name='Bank Produkte'>
                    <ToggleBox isOpen={this.props.scrollToElement === 'Bank Produkte'}
                               title='Bank Produkte'>
                        <>
                            <p>Banken und deren Produkte begleiten uns durch das ganze Leben. Unabdingbar für die
                                Abwicklung
                                von Kapitalanlagen ist das Girokonto.</p>
                            <p>Wir nutzen hierfür das Augsburger Konto Flex inkl. Depot bei der European Bank for Financial Services (ebase).</p>
                        </>
                    </ToggleBox>
                </Element>
                <Element name='Standardisierte Vermögensverwaltung'>
                    <ToggleBox isOpen={this.props.scrollToElement === 'Standardisierte Vermögensverwaltung'}
                               title='Standardisierte Vermögensverwaltung'>
                        <>
                            <p>Die standardisierte Vermögensverwaltung unterscheidet sich von der individuellen
                                Vermögensverwaltung im Wesentlichen darin, dass der Kunde keine Vorgaben in Bezug auf
                                die
                                Anlagestrategie machen kann.
                                Sie ist für Kunden geeignet, wenn die standardisierten Produkte den Anlagewünschen des
                                Kunden entsprechen.
                                Diese Einschränkung kann sogar von Vorteil sein, wenn man bspw. in der Vergangenheit mit
                                eigenen Anlagestrategien nicht die gewünschten Ziele erreichen konnte oder die
                                Verwaltung
                                des eigenen Vermögens als zu zeitaufwendig oder Nerven aufreibend wahrgenommen wurde.
                                Des Weiteren eignen sich derartige Lösungen auch für kleinere Anlagevolumen.</p>
                        </>
                    </ToggleBox>
                </Element>
                <Element name='Solvium Container'>
                    <ToggleBox isOpen={this.props.scrollToElement === 'Solvium Container'}
                               title='Solvium Container'>
                        <>
                            <p>Solvium Container Invest bietet Privatanlegern und institutionellen Investoren die
                                Möglichkeit zur Investition in Ausrüstungsgegenstände aus dem Transportbereich.</p>
                            <p>Solvium bietet ein sehr attraktives Investment mit überschaubarer Laufzeit, bei dem Sie
                                Eigentümer von bereits vermieteten 20 bzw. 40-Fuß-Standardcontainern (bekannt als
                                Seecontainer) werden.
                                Solvium übernimmt dabei für Sie die komplette Verwaltung über die gesamte
                                Vertragslaufzeit
                                und sichert Ihnen eine feste monatliche Miete je Container zu.
                                Am Ende der Laufzeit kauft Solvium die Container zum vereinbarten Festpreis von Ihnen
                                zurück.</p>
                                <a href="https://player.vimeo.com/progressive_redirect/playback/799419198/rendition/1080p/file.mp4?loc=external&signature=7dd789d940aa4621c1f2351bb4e4ddc0cc94df8d389ebcfef7ac804e0617b1fe" rel="noreferrer" target="_blank">Container-Ratgeberfilm für Verbraucher</a>
                        </>
                    </ToggleBox>
                </Element>
                <Element name='Solvium Wechselkoffer'>
                    <ToggleBox isOpen={this.props.scrollToElement === 'Solvium Wechselkoffer'}
                               title='Solvium Wechselkoffer'>
                        <>
                            <p>Mit dieser Vermögensanlage haben Anleger die Möglichkeit, neue bzw. gebrauchte und
                                bereits
                                vermietete Wechselkoffer (LKW-Ladecontainer mit ausklappbaren Standbeinen) zu erwerben.
                                Solvium übernimmt dabei für Sie die komplette Verwaltung über die gesamte
                                Vertragslaufzeit
                                und sichert Ihnen eine feste monatliche Miete je Wechselkoffer zu.
                                Am Ende der Laufzeit kauft Solvium die Wechselkoffer zum vereinbarten Festpreis von
                                Ihnen
                                zurück.</p>
                                <a href="https://player.vimeo.com/external/267672991.hd.mp4?s=be73abb1ddba10923dcc1c7cc7af57a0d5c52fda&profile_id=175" rel="noreferrer" target="_blank">Wechselkoffer-Ratgeberfilm für Verbraucher</a>
                        </>
                    </ToggleBox>
                </Element>
                <Element name='Thamm & Partner'>
                    <ToggleBox isOpen={this.props.scrollToElement ==='Thamm & Partner'}
                               title='Thamm & Partner'>
                        <>
                            <p>Als Immobiliengesellschaft konzentriert sich die Thamm & Partner GmbH auf
                                sanierungsbedürftige Wohnobjekte in innerstädtischer Metropollage.
                                Aus vernachlässigten und deshalb zum Teil unansehnlichen Gebäuden entstehen ausgesuchte
                                Denkmaljuwelen mit herrlichem Wohngefühl und Liebe zum Detail.
                                Thamm & Partner hält die Immobilien nach Hebung des Wertsteigerungspotentials entweder
                                im
                                Eigenbestand oder führt sie über den Verkauf Anlegern oder Eigentümern zu.
                                Anleger profitieren als Gesellschafter von der gesamten Wertschöpfungskette der
                                Immobilien.</p>
                                <p><a href="https://ivm-online.net/thamm-partner/" rel="noreferrer" target="_blank">Thamm & Partner Homepage</a></p>
                                <video width="750" height="500" controls >
                                <source src="http://www.va-h.de/thamm_partner.mp4" type="video/mp4"/>
                                </video>
                        </>
                    </ToggleBox>
                </Element>
                <Element name='Deutsche Finance Group AG'>
                    <ToggleBox isOpen={this.props.scrollToElement === 'Deutsche Finance Group AG'}
                               title='Deutsche Finance Group AG'>
                        <>
                            <p>Die Deutsche Finance Group mit Sitz in München und Präsenzen in London, Zürich, Luxemburg
                                und
                                Denver ist als Finanzdienstleistungskonzern in den Geschäftsbereichen Strategieberatung,
                                Asset Management, Fund Management, Anlageberatung und Anlegerverwaltung tätig und
                                spezialisiert auf institutionelle Private Market Investments in den Assetklassen
                                Immobilien,
                                Private Equity Real Estate und Infrastruktur.
                                Durch innovative Finanzstrategien und fokussierte Asset Management-Beratung bietet die
                                Deutsche Finance Group privaten, professionellen und institutionellen Investoren
                                exklusiven
                                Zugang zu internationalen Märkten und deren Investment-Opportunitäten.</p>
                                <a href="https://www.youtube.com/watch?v=L19ho20h4Vk" rel="noreferrer" target="_blank">Erklärfilm</a>
                                
                        </>
                    </ToggleBox>
                </Element>
                <Element name='RWB Private Capital Emissionshaus AG'>
                    <ToggleBox isOpen={this.props.scrollToElement === 'RWB Private Capital Emissionshaus AG'}
                               title='RWB Private Capital Emissionshaus AG'>
                        <>
                            <p>Keine andere Anlageform verbindet die Zielsetzung „maximale Rendite bei maximaler
                                Sicherheit“ konzeptionell so effizient wie ein Private Equity Dachfonds. Denn nur hier haben Sie als
                                Privatanleger die Möglichkeit, an der unternehmerischen Wertschöpfung hunderter
                                Unternehmen
                                weltweit teilzuhaben und durch diese Streuung gleichzeitig ein hohes Maß an Sicherheit
                                zu erreichen. Als eines der ersten Emissionshäuser hat RWB Private Equity Investitionen auch für
                                Privatanleger zugänglich gemacht und gehört heute – mit über 150 weltweit
                                abgeschlossenen
                                Fondsbeteiligungen – zu den international erfahrensten Dachfondsinitiatoren in
                                Deutschland.</p>
                                <a href="http://www.rwbcapital.de/videos/pcf/" rel="noreferrer" target="_blank">Erklärfilm</a>
                        </>
                    </ToggleBox>
                </Element>
                <div className="zurück-container">
                    <div className="zurück-knopf" onClick={()=>{this.props.setElement('Leistungen'); this.setState({active: Leist})}}><Link to='/' >{"zurück zu Leistungen"}</Link></div>
                </div>
            </div>
        )
    }
}
