import React, {PureComponent} from 'react';
import Unternehmen from "./context/Unternehmen";
import {Leistungen} from "./context/Leistungen";
import Ansprechpartner from "./context/Ansprechpartner";
import Partner from "./context/Partner";
import {scroller} from 'react-scroll';

//import News from './context/News';


export default class Home extends PureComponent<{setElement: (value: string)=>void, scrollToElement: string}, {}> {

    componentDidMount(): void {
        const {scrollToElement} = this.props

        if(scrollToElement){
            scroller.scrollTo(scrollToElement, {})
        }
    }

    componentDidUpdate(prevProps: Readonly<{ setElement: (value: string) => void; scrollToElement: string }>, prevState: Readonly<{}>, snapshot?: any): void {

        if(this.props.scrollToElement !== prevProps.scrollToElement){
            scroller.scrollTo(this.props.scrollToElement,{})
        }
    }

    render(): React.ReactNode {

        return (
            <div className='screen-container'>
                <Unternehmen/>
                <div className='div-spacer-between-containers-unternehmen'></div>
                <div className='div-spacer-between-containers'></div>
                <Ansprechpartner/>
                <div className='div-spacer-between-containers'></div>
                <Leistungen  setElement={this.props.setElement}/>
                <div className='div-spacer-between-containers'></div>
                <Partner/>
                <div className='div-spacer-between-containers-partner'></div>
            </div>
        );
    }
}
