import React, {PureComponent} from 'react'
import {Element} from 'react-scroll'
import {ContextBox} from '../../components/ContextBox'
import {IProfile, ShowRoom, SinglePicture} from "../../components/ShowRoom";

interface IAnsprechpartner {
    id?: string,
}

const mitarbeiter = [
    {
        picture: "./images/nikos.jpg",
        name: "Nikos Angelidis",
        job: "Versicherungsfachmann",
        title: "Versicherungskonzepte"
    },
    {
        picture: "./images/Sandra_Waldorf.jpg",
        name: "Sandra Waldorf",
        job: "Versicherungsfachfrau",
        title: "Innendienst / Backoffice"
    }

] as IProfile[]

const geschaeftsfuehrer = {
    picture: "./images/Urs-Hoffmann_KNH9389.jpg",
    name: "Urs Hoffmann",
    job: "Geschäftsführer",
    title: ""
}



export default class Ansprechpartner extends PureComponent<IAnsprechpartner, {}>{


    render(): React.ReactNode {


        return (
            <Element id='Ansprechpartner' name='Ansprechpartner' className='ansprechpartner'>
                <ContextBox className='contextBox-gray mobile'><h2>Ansprechpartner</h2></ContextBox>
            <div className='margin-top-scroll'/>
                <div className='box-wrapper'>
                    {/*GESCHÄFTSFÜHRER*/}
                    <ContextBox className='contextBox-blue box-one'>
                        <SinglePicture profile={geschaeftsfuehrer}/>
                    </ContextBox>
                </div>
                {/*MITARBEITER*/}
                <ContextBox className='contextBox-gray box-three isDesktopShown'>
                    <ShowRoom profiles={mitarbeiter}/>
                </ContextBox>
                <ContextBox className='contextBox-shadow box-three isMobileShown'>
                    <SinglePicture profile={mitarbeiter[0]}/>
                    <SinglePicture profile={mitarbeiter[1]}/>
                </ContextBox>
            </Element>
        )
    }
}
