import React, {PureComponent} from 'react'

export interface IToggleBoxProps {
    title: string,
    isOpen?: boolean
}

export interface IToggleBoxState {
    open: boolean
}

export class ToggleBox extends PureComponent<IToggleBoxProps, IToggleBoxState> {
    state = {
        open: false
    }

    componentDidMount(){
        const {isOpen} = this.props
        if(isOpen){
            this.setState({open: isOpen})
        }
    }

    toggleBox = () => {
        this.setState({open: !this.state.open})
    }

    render(): React.ReactNode {
        const {open} = this.state

        return (
            <div className='toggle-box'>
                <div className='toggle-head' onClick={this.toggleBox}>
                    <h4>{this.props.title}</h4>
                </div>
                {open &&
                <div className='toggle-body'>
                    {this.props.children}
                </div>
                }
            </div>
        )
    }
}
