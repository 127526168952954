import React, {PureComponent} from 'react'
import {Element} from 'react-scroll'
import {ContextBox} from '../../components/ContextBox'
import {SinglePicture} from "../../components/ShowRoom";

interface IPartner {
    id?: string,
}

const sek = {
    picture: "./images/Frank-von-Stachelski_KNH9657.jpg",
    name: "Frank von Stachelski",
    job: "Dipl. Bankbetriebswirt (BA)",
    title: "Geschäftsführer"
}

export default class Partner extends PureComponent<IPartner, {}> {


    render(): React.ReactNode {
        return (
            <Element id='Partner' name='Partner' className='partner'>
                <div className='flex-start'>
                    <ContextBox className='contextBox-blue box-one'>
                        <h2 className='white'>Kooperationspartner</h2>
                    </ContextBox>
                </div>
                <div className='box-wrapper'>
                    <div className='center-column-only'>
                        <div className='box-two'>
                            <img className='title-picture' alt="" src={'./images/PNG_Logo.png'}/>

                        </div>
                        <ContextBox className='box-three'>
                            <div className='center-column-only'>
                                <span>Von-Steinbeis-Straße 16a</span>
                                <span>78476 Allensbach</span>
                                <span>07533 | 949 69 89</span>
                                <span>post (at) fvs-consulting.de</span>
                            </div>
                        </ContextBox>
                    </div>
                    <div className='box-four'>
                        <SinglePicture profile={sek}/>
                    </div>
                </div>
            </Element>
        )
    }
}
