import React, {PureComponent} from 'react';
import {Map, Marker, GoogleApiWrapper} from 'google-maps-react';
import {send} from 'emailjs-com'
        const serviceId: string = process.env.REACT_APP_EMAIL_JS_SERVICE_ID || 'No Service Id'
        const template: string = process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID || 'No Template Id'
        const userId: string = process.env.REACT_APP_EMAIL_JS_USER_ID || 'No User Id'

class MapContainer extends PureComponent<any, any> {
    state = {
        name: '',
        email: '',
        tel: '',
        nachricht: '',
        accept: false,
        error: '',
        success: false
    }

    componentDidMount(): void {
        // console.log("This must be deleted", serviceId, template, userId)
    }

    sendEmail = () => {
        const {name, email, tel, nachricht, accept} = this.state

        if(!accept)return

        const temp = {
            name: name,
            email: email,
            tel: tel,
            nachricht: nachricht
        }

        send(serviceId, template, temp,userId).then(this.postSuccess, this.errorMessage)
    }

    postSuccess = () =>{
            this.setState({success:true, error: ''})
    }

    errorMessage = (error: any) => {
        this.setState({error: error.message, success: false})
    }


    render() {
        return (
                <div className='full-screen flex-end'>
            <div className='screen-container '>
                <img className='kontakt-picture' alt="" src={'./images/3TS_2252.jpg'}/>
                <div className='column-center'>
                    <h2>Jetzt anfragen</h2>
                    <span>Falls Sie noch Fragen haben, zögern Sie nicht uns zu kontaktieren. Wir rufen Sie natürlich auch gerne zurück.</span>
                    <div className='column'>
                        <div className='row spacebetween'>
                            <input className='kontakt-input' type='text' name='name' placeholder='Name' value={this.state.name} onChange={(e)=>{this.setState({name: e.target.value})}}/>
                            <input className='kontakt-input'  type='email' name='email' placeholder='E-Mail' value={this.state.email} onChange={(e)=>{this.setState({email: e.target.value})}}/>
                        </div>
                        <input className='kontakt-input' type='text' name='tel' placeholder='Telefonnummer' value={this.state.tel} onChange={(e)=>{this.setState({tel: e.target.value})}}/>
                        <textarea className='kontakt-input' placeholder='Ihre Nachricht'  value={this.state.nachricht} onChange={(e)=>{this.setState({nachricht: e.target.value})}}/>
                        <div className='kontakt-input' onClick={()=>{this.setState({accept: !this.state.accept})}}>
                            <input type='checkbox' defaultChecked={this.state.accept}/>
                            <span>Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an kontakt(at)va-h.de widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer <a href="http://va-h.de/Datenschutzerklärung">Datenschutzerklärung</a>.</span>
                        </div>
                        <div className='row kontakt-input1'>
                            <button onClick={this.sendEmail}>Jetzt absenden</button>
                            {this.state.success && <span>Nachricht wurde erfolgreich versendet</span>}
                            {this.state.error !== '' && <span>{this.state.error}</span>}
                        </div>
                    </div>
                </div>
                <div className='map-wrapper'>
                    <Map google={this.props.google} zoom={11.5} initialCenter={{
                        lat: 47.7257279,
                        lng: 9.072479915
                    }}>

                        <Marker/>
                    </Map>
                </div>
                </div>
            </div>
        );
    }
}

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY as string
})(MapContainer)